import React from 'react'

const About = () => {
  return (
    <div className='w-full h-screen'>
        <div className='w-full h-full flex justify-center items-center'>
            <h1 className='text-4xl'>We will update the about page soon!</h1>
        </div>
    </div>
  )
}

export default About