import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='w-full h-screen text-white'>
      <div className='w-full h-full flex flex-col justify-center items-center'>
        <h1>Sorry, that page cannot be found.</h1>
        <Link className='hover:text-slate-600 duration-100 transition-all ease-in-out cursor-pointer' to='/'>Home Page...</Link>
      </div>
    </div>
  )
}

export default NotFound