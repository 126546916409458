import React from 'react'
import { Link } from 'react-router-dom';
import fbIcon from './img/icons/facebook.svg'

const PageFooter = () => {
  return (
    <footer className='w-full h-300 bg-primaryBlue flex flex-col md:flex-row justify-center items-center text-white text-center md:text-left'>
        <nav className='w-full md:w-4/12 flex flex-col items-center'>
            <div className='mb-4 md:mb-0'>
                <p className='text-xl pb-0 md:pb-4'>Product Links</p>
                <Link to='/Product' className='text-sm text-gray-400'>Product</Link>
            </div>
        </nav>

        <nav className='w-full md:w-4/12 flex flex-col items-center'>
            <div className='mb-4 md:mb-0'>
                <p className='text-xl pb-0 md:pb-4'>Company</p>
                <Link to='/About' className='text-sm text-gray-400'>About</Link>
            </div>
        </nav>

        <nav className='w-full md:w-4/12 flex flex-col items-center'>
            <div className='mb-4 md:mb-0'>
                <p className='text-xl pb-0 md:pb-4'>Support</p>
                <Link to='/Contact' className='text-sm text-gray-400'>Contact</Link>
            </div>
        </nav>

        <nav className='w-full md:w-4/12 flex flex-col items-center'>
            <div className='mb-4 md:mb-0'>
                <p className='text-xl pb-0 md:pb-4'>Get in Touch</p>
                <a href='https://www.facebook.com/Alpha-Delight-Corp-106855528665304' target="_blank" rel="noopener noreferrer" className=''><img className='object-cover w-6 h-6' src={fbIcon} alt="" /></a>
            </div>
        </nav>
    </footer>
  )
}

export default PageFooter