import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { Header, PageFooter, Home, About, Product, Contact, NotFound } from './components';

const App = () => {
  return (
    <div className='w-screen h-auto flex flex-col items-center justify-center'>
        <Header />
        <main className='w-full'>
          <Routes>
            <Route exact path='/' element={ <Home /> } />
            <Route path='/about' element={ <About /> }/>
            <Route path='/product' element={<Product />} />
            <Route path='/contact' element={ <Contact /> }/>
            <Route path="*" element={ <NotFound /> } />
          </Routes>
        </main>
        <PageFooter />
    </div>
  )
}

export default App