import React from 'react';
import disposable_slippers from './img/products/disposable_slippers.png';
import paraffin_wax_peach from './img/products/paraffin_wax_peach.png';
import paraffin_wax_lavender from './img/products/paraffin_wax_lavender.png';
import toes_separators from './img/products/toes_separators.png';
import acetone from './img/products/acetone.png';
import paraffin_wax_liners from './img/products/paraffin_wax_liners.png';
import spa_pedicure_bowl_liner from './img/products/spa_pedicure_bowl_liner.png';
import dip_powder_base_2 from './img/products/dip_powder_glue_base_2.png';
import dip_powder_coat_4 from './img/products/dip_powder_glue_coat_4.png';

const products = [
  {
    id: 1,
    name: 'Disposable Slippers',
    href: '#',
    imageSrc: `${disposable_slippers}`,
    imageAlt: "Front of Disposable Slippers.",
    color: 'Black',
  },
  {
    id: 2,
    name: 'Paraffin wax - Peach',
    href: '#',
    imageSrc: `${paraffin_wax_peach}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
  {
    id: 3,
    name: 'Paraffin wax - Lavender',
    href: '#',
    imageSrc: `${paraffin_wax_lavender}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
  {
    id: 4,
    name: 'Toes separators',
    href: '#',
    imageSrc: `${toes_separators}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
  {
    id: 5,
    name: 'Acetone',
    href: '#',
    imageSrc: `${acetone}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
  {
    id: 6,
    name: 'Paraffin wax liners',
    href: '#',
    imageSrc: `${paraffin_wax_liners}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
  {
    id: 7,
    name: 'Spa pedicure bowl liner',
    href: '#',
    imageSrc: `${spa_pedicure_bowl_liner}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
  {
    id: 8,
    name: 'Dip Powder Glue Base #2',
    href: '#',
    imageSrc: `${dip_powder_base_2}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
  {
    id: 9,
    name: 'Dip Powder Glue Coat #4',
    href: '#',
    imageSrc: `${dip_powder_coat_4}`,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
  },
]

const Product = () => {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 text-center">Products</h2>

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <div key={product.id} className="group relative">
              <div className="w-full min-h-60 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-60 lg:aspect-none">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href={product.href}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Product