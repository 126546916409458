import React from 'react';
import { Link } from 'react-router-dom';
import LogoText from './img/logo.png';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  return (
    <div className='w-full h-full relative z-50 text-white'>
        <header className='w-full h-24 bg-primaryBlue'>
            {/* Desktop & Tablet */}
            <nav className='hidden md:flex flex-row w-full h-full items-center'>
                {/* Logo */}
                <div className='w-3/12 flex justify-center'>
                    <Link to='/'><img className='w-56 object-cover' src={LogoText} alt="Text logo" /></Link>
                </div>
                {/* Links */}
                <div className='w-6/12 flex justify-center'>
                    <Link to='/' className='px-4 lg:px-10'>Home</Link>
                    <Link to='/about' className='px-4 lg:px-10'>About</Link>
                    <Link to='/product' className='px-4 lg:px-10'>Products</Link>
                    <Link to='/contact' className='px-4 lg:px-10'>Contact</Link>
                </div>
                {/* CTA */}
                <div className='w-3/12 flex justify-center text-black'>
                    <button className='px-8 py-3 bg-primaryGold rounded-sm text-sm'>Login</button>
                </div>
            </nav>

            {/* Mobile */}
            <nav className='flex md:hidden flex-row w-full h-full items-center px-4'>
                {/* Logo */}
                <div className='w-1/2 flex justify-start'>
                    <Link to='/'><img className='w-56 object-cover' src={LogoText} alt="Text logo" /></Link>
                </div>
                {/* Links */}
                <div className='w-1/2 flex justify-end'>
                    <Popover className="relative">
                        {({ open }) => (
                            <>
                            <Popover.Button className={classNames(open ? 'text-gray-900' : 'text-gray-500', 'group inline-flex items-center text-base font-medium hover:text-gray-900')}>
                                <ChevronDownIcon className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')} aria-hidden="true"/>
                            </Popover.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-full mt-3 px-2 w-screen max-w-xs sm:px-0">
                                <div className="overflow-hidden">
                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                        <Link to='/about' className='-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150 text-base font-medium text-gray-900'>About</Link>
                                        <Link to='/product' className='-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150 text-base font-medium text-gray-900'>Products</Link>
                                        <Link to='/contact' className='-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150 text-base font-medium text-gray-900'>Contact</Link>
                                    </div>
                                </div>
                                </Popover.Panel>
                            </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </nav>
        </header>

        
    </div>
  )
}

export default Header